export type DownloadedImageAsset = {
  data: ArrayBuffer;
  mime: string;
};

export type MediaAssetSource = DownloadedImageAsset | string;

export type DownloadedImageUrl = string | DownloadedImageAsset;
export type DownloadedImageUrls = DownloadedImageUrl[];

export const isDownloadedAsset = (
  asset: string | Blob | DownloadedImageAsset | undefined
): asset is DownloadedImageAsset => {
  if (!asset) {
    return false;
  }

  return !!(asset as DownloadedImageAsset).mime;
};
