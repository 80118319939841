import rgba from 'color-rgba';
import type { KioskColors, LoyaltyColors, MenuColors } from '@grubbrr/nextgen-kiosk-client';

// eslint-disable-next-line camelcase
export function colors_to_style(
  colors: KioskColors | LoyaltyColors | MenuColors | undefined
): string {
  if (!colors) {
    return '';
  }
  const capRegex = /on([A-Z])/g;
  return Object.entries(colors)
    .map(([name, value]) => {
      if (!value) {
        return undefined;
      }
      const color = rgba(value);
      if (!color) {
        return undefined;
      }

      return `--color-${name.replace(capRegex, 'on-$1').toLowerCase()}: ${color[0]}, ${color[1]}, ${
        color[2]
      }`;
    })
    .filter((x): x is string => x !== undefined)
    .join('; ');
}
