<script lang="ts">
  // @ts-expect-error something is wrong with the pwa virtual generator, this actually works
  import { registerSW } from 'virtual:pwa-register';

  import { app_service } from 'business-states/src/lib/states/';
  import { screensaver } from 'business-states/src/lib/storage/screensaver';
  import Media from 'svelte-ui/src/lib/templates/components/Media.svelte';
  import type { MouseEventHandler } from 'svelte/elements';
  import { fade } from 'svelte/transition';

  registerSW();

  function load_app() {
    type LazyApp = typeof import('./LazyApp.svelte');
    return new Promise<LazyApp>((resolve) => {
      requestAnimationFrame(() => {
        import('./LazyApp.svelte').then((app: LazyApp) => {
          return resolve(app);
        });
      });
    });
  }

  const loadAppPromise = load_app();

  app_service.start();

  const handleRightClick: MouseEventHandler<Window> = (event) => {
    if (import.meta.env.VITE_DISABLE_RIGHT_CLICK) {
      // Prevent the default right click // context menu from showing up
      event.preventDefault();
    }
  };

  const screensaverStore = screensaver.stateStore;
  const screensaverMediaStore = screensaver.mediaStore;

  let currentIndex = 0;
  let screenSaverMedia = null;
  $: screenSaverMedia = $screensaverStore.show ? $screensaverMediaStore[currentIndex] : null;

  function handleMediaEnd() {
    currentIndex = (currentIndex + 1) % $screensaverMediaStore.length;
  }

  const onLoad = (video: CustomEvent<HTMLVideoElement>) => {
    const duration = video.detail?.duration ?? 5;
    setTimeout(() => {
      handleMediaEnd();
    }, duration * 1000);
  };
</script>

<svelte:window on:contextmenu={handleRightClick} />

{#if screenSaverMedia}
  <button
    class="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
    aria-hidden="true"
    type="button"
    tabindex="-1"
    in:fade|local
  >
    <Media
      on:load={screensaver.playing}
      id="background-media"
      src={screenSaverMedia}
      alt="Screen Saver"
      {onLoad}
    />
  </button>
{/if}

{#await loadAppPromise}
  {#if !$screensaverStore.show && $screensaverStore.loaded}
    <div
      class="w-full h-full flex flex-col bg-black text-white justify-center items-center font-custom-regular-lg"
    >
      Loading...
    </div>
  {/if}
{:then { default: LazyApp }}
  <LazyApp />
{/await}
