<script lang="ts">
  import type { DownloadedImageAsset } from 'business-states/src/lib/models';
  import MediaInternal from './MediaInternal.svelte';

  export let src: string | DownloadedImageAsset;
  export let alt = '';
  export let style = '';
  export let id = '';
  export let placeholder = '';
  export let cover = false;
  export let cssClass = '';
  export let cssWidth = 'w-full';
  export let cssHeight = 'h-full';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export let onLoad = (_video: CustomEvent<HTMLVideoElement>) => null;
</script>

<div class="relative {cssWidth} {cssHeight}">
  <div class="pb-full pointer-events-none" />
  <div class="absolute w-full h-full top-0 left-0">
    <MediaInternal
      {src}
      {alt}
      {style}
      {id}
      {placeholder}
      {cover}
      {cssClass}
      on:load={onLoad}
    />
  </div>
</div>
