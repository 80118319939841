// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { get, set, del } from 'idb-keyval';
import { writable, type Readable, type Writable, derived } from 'svelte/store';
import type { KioskColors } from '@grubbrr/nextgen-kiosk-client';
import { isDownloadedAsset } from '../models';
// eslint-disable-next-line camelcase
import { colors_to_style } from '../utils/color_utils';
import { Logger } from '../logger';
import type { DownloadedImageAsset } from '../models';

type StoredValue = {
  id: string;
  media: string | DownloadedImageAsset[];
  style: string;
};

export type ScreensaverState = {
  show: boolean;
  loaded: boolean;
  playing: boolean;
  tapped: boolean;
};

export interface IScreensaverStateManager {
  mediaStore: Readable<string | DownloadedImageAsset[]>;
  stateStore: Readable<ScreensaverState>;
  start(): void;
  stop(): void;
  tap(): void;
  playing(): void;
  untap(): void;
  update: (screensaver: (string | DownloadedImageAsset)[], colors?: KioskColors) => Promise<void>;
  clear(): Promise<void>;
}

export type ScreensaverInternalState = {
  show: boolean;
  loaded: boolean;
  playing: boolean;
  tapped: boolean;
} & Omit<StoredValue, 'id'>;

export class ScreensaverStateManager implements IScreensaverStateManager {
  mediaStore: Readable<string | DownloadedImageAsset[]>;
  stateStore: Readable<ScreensaverState>;

  private internalState: Writable<ScreensaverInternalState>;

  constructor() {
    this.internalState = writable({
      media: '',
      style: '',
      show: false,
      loaded: false,
      playing: false,
      tapped: false,
    });

    this.stateStore = derived(this.internalState, (internalState) => {
      return {
        show: internalState.show,
        loaded: internalState.loaded,
        playing: internalState.playing,
        tapped: internalState.tapped,
      };
    });

    this.mediaStore = derived(this.internalState, (internalState) => {
      return internalState.media;
    });

    this.loadScreensaver();
  }

  clear = async () => {
    try {
      await del('screensaver');
    } catch (error) {
      Logger.log('Dev', 'Error clearing screensaver', error);
    }
  };

  private setStateFromDb = (
    s: { media: string | DownloadedImageAsset[]; style: string } | undefined
  ) => {
    try {
      if (!s) {
        this.internalState.set({
          media: '',
          style: '',
          show: false,
          loaded: true,
          playing: false,
          tapped: false,
        });
        return;
      }
      const media =
        typeof s.media === 'string' || s.media instanceof Blob || isDownloadedAsset(s.media[0])
          ? s.media
          : '';
      this.internalState.set({
        media,
        style: (s.style as string) ?? '',
        show: media !== '',
        loaded: true,
        playing: false,
        tapped: false,
      });
    } catch {
      this.internalState.set({
        media: '',
        style: '',
        show: false,
        loaded: true,
        playing: false,
        tapped: false,
      });
    }
  };

  private loadScreensaver = async () => {
    this.setStateFromDb(await get('screensaver'));
  };

  update = async (screensaver: (string | DownloadedImageAsset)[], colors?: KioskColors) => {
    try {
      const media =
        screensaver.length > 0
          ? typeof screensaver[0] === 'string'
            ? screensaver.join('')
            : (screensaver as DownloadedImageAsset[])
          : '';

      const style = colors_to_style(colors);
      const stateToSave = { id: 'screensaver', media, style };
      await set('screensaver', stateToSave);
      this.setStateFromDb(stateToSave);
    } catch {
      //
    }
  };

  start = () => {
    this.internalState.update((s) => ({ ...s, show: true, playing: true }));
  };

  stop = () => {
    this.internalState.update((s) => ({ ...s, show: false, playing: false }));
  };

  tap = () => {
    this.internalState.update((s) => ({ ...s, tapped: true }));
  };

  playing = () => {
    this.internalState.update((s) => ({ ...s, playing: true }));
  };

  untap(): void {
    this.internalState.update((s) => ({ ...s, tapped: false }));
  }
}

export const screensaver: IScreensaverStateManager = new ScreensaverStateManager();
