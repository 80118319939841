<script
  lang="ts"
  context="module"
>
  import lottie from 'lottie-web';
</script>

<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte';
  export let src: string;

  const dispatch = createEventDispatcher<{ load: undefined }>();

  let lottie_div: HTMLDivElement;

  function on_load() {
    dispatch('load');
  }

  onMount(() => {
    const animationData = JSON.parse(src);
    const animation = lottie.loadAnimation({
      animationData,
      container: lottie_div,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
        progressiveLoad: true,
        hideOnTransparent: true,
      },
    });
    on_load();

    return () => {
      animation.destroy();
    };
  });

</script>

<div
  class="h-full w-full"
  bind:this={lottie_div}
/>
